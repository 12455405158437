"use client";

import React, { FC } from "react";

import { APIResponseCollection } from "types/models";

import SlideOver from "components/custom/SlideOver";
import Icon from "components/tailwind-ui/Icon";

import NavbarMobileMenu from "../MobileView/NavbarMobileView";

type TProps = {
  socials?: { accessor: string; href: string }[];
  services?: APIResponseCollection<"api::service.service">;
};

const ClientProxy: FC<TProps> = (props) => {
  const { socials, services } = props;

  return (
    <SlideOver
      trigger={({ handleOpen }) => (
        <button className="mx-2" onClick={handleOpen}>
          <Icon accessor="bars" />
        </button>
      )}
    >
      {() => <NavbarMobileMenu socials={socials} services={services} />}
    </SlideOver>
  );
};

export default ClientProxy;
